import Table from "@/components/table-v2/Table";
import useAutoSyncBlockContent from "@/hooks/useAutoSyncBlockContent";
import { Spinner } from "@/Icons";
import React from "react";
import { RenderBlockContentProps } from "../RenderBlockContent";
import { FocusableBlockContent } from "../useFocusableBlockContent";
import { ITableContent } from "./types";

/**
 * Component responsible for:
 * 1. fetching block content from the report server
 * 2. rendering the content using other components
 * 3. writing back changes to the report server
 */
function RenderTableBlockContentWithoutRef(
  { reportId, blockId, readOnly }: RenderBlockContentProps,
  ref?: React.Ref<FocusableBlockContent>,
) {
  const { draft, setDraft, queryResult } =
    useAutoSyncBlockContent<ITableContent>(blockId, reportId, {
      autoSaveOptions: { wait: 1000, maxWait: 5000, enabled: !readOnly },
    });

  if (!draft && queryResult.isLoading) {
    return (
      <div className="flex w-full items-center justify-center">
        <Spinner className="h-6 w-6 animate-spin" />
      </div>
    );
  }
  return <Table ref={ref} table={draft} onTableChange={setDraft} />;
}
const RenderTableBlockContent = React.forwardRef(
  RenderTableBlockContentWithoutRef,
);
export default RenderTableBlockContent;
