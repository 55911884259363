import { useCallback, useMemo } from "react";
import { useSearchParams } from "react-router-dom";

const COMPLETION_MODES = ["display", "capture"] as const;
type CompletionMode = (typeof COMPLETION_MODES)[number];
const DEFAULT_MODE = "capture";

function getMode(searchParams: URLSearchParams) {
  const completionMode = searchParams.get("mode") || DEFAULT_MODE;
  if (COMPLETION_MODES.includes(completionMode as CompletionMode)) {
    return completionMode as CompletionMode;
  } else {
    return DEFAULT_MODE;
  }
}
/**
 * Hook to get and set the completion mode from the URL
 * @returns completionMode and setCompletionMode
 */
export default function useCompletionMode() {
  const [searchParams, setSearchParams] = useSearchParams();

  const completionMode = useMemo(() => getMode(searchParams), [searchParams]);

  const setCompletionMode = useCallback(
    (mode: CompletionMode) => {
      setSearchParams((params) => {
        params.set("mode", mode);
        return params;
      });
    },
    [setSearchParams],
  );
  return [completionMode, setCompletionMode] as const;
}
