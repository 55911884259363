import { createBlockQuery } from "@/services/reports/useBlock";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import useCompletionMode from "./useCompletionMode";
import useCurrentReportBlockId from "./useCurrentReportBlockId";

type UseIsBlockReadOnlyReturnType =
  | readonly [true, string]
  | readonly [false, undefined];

export default function useIsBlockReadOnly(
  id?: number,
): UseIsBlockReadOnlyReturnType {
  const [completionMode] = useCompletionMode();
  const currentBlockId = useCurrentReportBlockId(id);
  const { t } = useTranslation();
  const { data: signed } = useQuery({
    ...createBlockQuery(currentBlockId),
    select: (block) => block.signed,
  });
  if (completionMode == "display") {
    return [true, t("readonly.display-mode") as string] as const;
  }
  if (signed) {
    const who = signed.user.display ?? t("readonly.unknown");
    const when = new Date(signed.timestamp).toLocaleDateString();
    return [true, t("readonly.signed-block", { when, who }) as string] as const;
  }
  return [false, undefined] as const;
}
