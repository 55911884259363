import Reference, {
  TiroPatientReference,
} from "@/data-models/value-models/reference";
import createProtector, {
  CreateProtectorConfig,
  PROTECTOR_CONFIG_DEFAULTS,
} from "@/pages/auth/createProtector";
import { ALL_PATIENTS_PATH } from "@/pages/patients/const";
import { QueryClient } from "react-query";
import { LoaderFunction, redirect } from "react-router";
import { ApiError } from "@/services/content/content-client";
import client, {
  Encounter_Input as Encounter,
  ReportIn,
  ReportOut,
} from "@/services/reports/client";
import keyFactory from "@/services/reports/keyFactory";
import {
  createReportsQuery,
  CreateReportsQuery,
} from "@/services/reports/useReports";
import {
  createTemplateReportQuery,
  CreateTemplateReportQuery,
} from "@/services/useCreateReportFromTemplate";
import { coerce, create, integer, pattern, string } from "superstruct";
import { ALL_REPORTS_PATH } from "../const";
import { NEW_REPORT_PATH } from "../new/const";
import parseURLParams from "./parseURLParams";
import createReportFromTemplate from "@/services/createReportFromTemplate";

export type CreateLoaderConfig = {
  queryClient: QueryClient;
  createReportsQuery: CreateReportsQuery;
  createTemplateReportQuery: CreateTemplateReportQuery;
  createReportFn: typeof client.v1.createReportV1;
} & CreateProtectorConfig;

export const LOADER_CONFIG_DEFAULTS = {
  createReportFn: ({ requestBody }: { requestBody: ReportIn }) =>
    client.v1.createReportV1({ requestBody }),
  createReportsQuery,
  createTemplateReportQuery,
  ...PROTECTOR_CONFIG_DEFAULTS,
};

export default (config: CreateLoaderConfig): LoaderFunction =>
  async ({ request, params, context }) => {
    const {
      queryClient,
      createReportsQuery,
      createTemplateReportQuery,
      createReportFn,
      ...protectorConfig
    } = config;

    // Check if user allowed to access this page
    console.debug("Checking if user allowed to access this page");
    const protector = createProtector({ ...protectorConfig, queryClient });
    const response = await protector({ request, params, context });
    if (response) return response;

    console.debug("Parse patient id from URL params");
    let patientId: number;
    try {
      patientId = create(
        params.patientId,
        coerce(integer(), pattern(string(), /\d+/), (s) => parseInt(s)),
      );
    } catch (e) {
      console.debug("Error parsing patient id from URL params", e);
      return redirect(`/${ALL_PATIENTS_PATH}`);
    }

    // Parse URL params
    console.debug("Parsing URL params");
    const { encounter, template: templateId } = parseURLParams(request);

    // Check if a report exists for current encounter
    console.debug("Checking if a report exists for current encounter");
    let reports: ReportOut[] = [];
    if (encounter && patientId) {
      reports = await queryClient.fetchQuery(
        createReportsQuery({
          subject: patientId.toString(),
          encounterIdentifier: encounter?.toToken(),
          limit: 1,
          offset: 0,
          sort: "modified:desc",
        }),
      );
    }
    console.debug("Reports found", reports);

    let report: ReportOut;
    if (reports.length > 0) {
      console.debug("Report already exists for current encounter");
      report = reports[0];
      queryClient.setQueryData(keyFactory.report(report.id), report);
    } else {
      console.debug("Report does not exist for current encounter");

      if (!templateId) {
        console.debug("No template specified, redirecting to new report");
        const searchParams = new URLSearchParams();
        if (encounter) searchParams.set("encounter", encounter.toToken());
        return redirect(
          `/${NEW_REPORT_PATH.replace(
            ":patientId",
            patientId.toString(),
          )}?${searchParams.toString()}`,
        );
      }

      try {
        console.debug("Template specified, creating report from template");
        report = await createReportFromTemplate(templateId, {
          queryClient,
          createReportFn,
          createTemplateReportQuery,
          encounter: encounter
            ? new Reference({
                identifier: encounter,
                type: "Encounter",
              })
            : undefined,
          subject: TiroPatientReference.fromPatientId(patientId),
        });
      } catch (e) {
        // re-throw if not 404
        if (!(e instanceof ApiError) || e.status !== 404) {
          console.debug("Error creating report from template", e);
          throw e;
        }
        const searchParams = new URLSearchParams();
        if (encounter) searchParams.set("encounter", encounter.toToken());
        return redirect(
          `/${NEW_REPORT_PATH.replace(
            ":patientId",
            patientId.toString(),
          )}?${searchParams.toString()}`,
        );
      }
    }

    // Redirect to report dispatch page
    return redirect(
      `/${ALL_REPORTS_PATH.replace(":patientId", patientId.toString())}/${
        report.id
      }`,
    );
  };
