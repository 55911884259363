import React, { useCallback, useContext } from "react";
import classNames from "classnames";
import { Combobox, ComboboxProps } from "@headlessui/react";
import { is } from "superstruct";
import { FloatingPortal } from "@floating-ui/react";
import {
  Control,
  ControllerProps,
  FieldPath,
  FieldValues,
  useController,
} from "react-hook-form";
import { CodingModel } from "@/data-models/value-models/structs";
import useCodeCombobox from "@/components/base/CodeCombobox/useCodeCombobox";
import Button from "@/components/base/CodeCombobox/Button";
import { IHumanReadableCoding } from "@/data-models/value-models/types";
import CodeComboboxResults, {
  CodingResultsContainerRefContext,
} from "@/components/base/CodeCombobox/CodeComboboxResults";
import { DefinedValueSet } from "@/services/content/content-client";

const DUMMY_TRANSFORM = {
  input: (o: any) => o,
  output: (o: any) => o,
};

export interface CodingComboboxInputProps<
  TFieldValues extends FieldValues,
  TValue = unknown,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
> extends Omit<
    ControllerProps<TFieldValues, TName>,
    "render" | "defaultValue"
  > {
  name: string;
  control?: Control<TFieldValues>;
  className?: string;
  defaultValue?: TValue | null;
  defaultCode?: IHumanReadableCoding | null;
  placeholder?: string;
  size?: number;
  count?: number;
  active?: boolean;
  disabled?: boolean;
  disableWhenQueryIsEmpty?: boolean;
  valueSet: string | DefinedValueSet;
  transform?: {
    input: (value: TValue) => IHumanReadableCoding;
    output: (value: IHumanReadableCoding) => TValue;
  };
}

function CodingComboboxInput<
  TFieldValues extends FieldValues = FieldValues,
  TValue = IHumanReadableCoding,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
>({
  valueSet,
  name,
  defaultCode = null,
  disableWhenQueryIsEmpty,
  disabled,
  size = 100,
  placeholder,
  count,
  active,
  className,
  transform: providedTransform,
  ...props
}: CodingComboboxInputProps<TFieldValues, TValue, TName>) {
  const buttonRef = React.useRef<HTMLButtonElement>(null);
  const containerRef = useContext(CodingResultsContainerRefContext);
  const {
    isOpen,
    filteredCodes,
    handleQueryChange,
    by,
    displayValue,
    handleCodeChange,
    referenceProps,
    floatingProps,
    fetchStatus,
    helpers,
  } = useCodeCombobox(valueSet, {
    count,
    allowedPlacements: ["bottom-start", "bottom-end"],
    disableWhenQueryIsEmpty,
  });
  const transform = providedTransform ?? DUMMY_TRANSFORM;
  const { field } = useController({
    name,
    defaultValue: defaultCode,
  });
  const handleChange = useCallback(
    (option: IHumanReadableCoding | null) => {
      field.onChange(option && transform.output(option));
    },
    [field, transform],
  );

  return (
    <Combobox
      as="div"
      className={classNames(
        "inline-flex max-w-md items-baseline justify-between",
        className,
      )}
      value={field.value ? transform.input(field.value) : null}
      onChange={handleCodeChange(handleChange)}
      by={by}
      disabled={disabled}
      nullable
      {...props}
    >
      {({ open }) => (
        <div
          {...referenceProps}
          className={classNames("relative grow rounded-md shadow-sm", {
            "ring-2 ring-blue-600 ring-offset-1": active,
          })}
        >
          <Combobox.Input
            autoComplete="off"
            className="w-full rounded-md border-0 bg-white py-1.5 pl-3 pr-8 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-blue-600 disabled:cursor-not-allowed disabled:bg-gray-100 disabled:text-gray-500 sm:text-sm sm:leading-6"
            onClick={() => buttonRef.current?.click()}
            type="text"
            placeholder={placeholder}
            size={size}
            onChange={handleQueryChange}
            displayValue={displayValue}
            onBlur={field.onBlur}
          />
          <Combobox.Button
            ref={buttonRef}
            as={Button}
            loading={fetchStatus.isFetching}
            error={fetchStatus.isError}
            success={fetchStatus.isSuccess}
          />
          <FloatingPortal root={containerRef.current}>
            <CodeComboboxResults
              {...floatingProps}
              open={open && isOpen}
              minimal
              codes={filteredCodes}
              helpers={helpers}
            />
          </FloatingPortal>
        </div>
      )}
    </Combobox>
  );
}

export default CodingComboboxInput;
