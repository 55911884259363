// Check if the last modified user is different from the current user
// and if the last modified timestamp is within the last 1 minute

import useCurrentReportId from "@/hooks/useCurrentReportId";
import { createReportQuery } from "@/services/reports/useReport";
import { currentUserDataQuery } from "@/services/reports/useUserData";
import { parseISO } from "date-fns";
import { useEffect, useRef } from "react";
import { useQuery } from "react-query";

type LastModification = {
  user: string;
  timestamp: Date;
};

export default function useReportRecentlyModified({
  timeDifference = 60 * 1000, // 1 minute
  nowFn = () => Date.now(),
  onRecentlyModified,
}: {
  timeDifference?: number;
  /*
   * Function to get the current time. Defaults to `Date.now()`.
   */
  nowFn?: () => number;
  onRecentlyModified?: (lastModification: LastModification) => void;
}) {
  const reportId = useCurrentReportId();
  const { data: currentUser } = useQuery(currentUserDataQuery);
  const { data: lastModified } = useQuery({
    ...createReportQuery(reportId),
    select: (report) => report.lastModified,
  });
  const onRecentlyModifiedRef = useRef(onRecentlyModified);
  const nowFnRef = useRef(nowFn);

  // If so, show a dialog warning the user that someone else is editing the report
  useEffect(() => {
    if (!lastModified) return;
    if (!currentUser) return;
    const differentUser = lastModified.user.id !== currentUser.id;
    const parsedTimestamp = parseISO(lastModified.timestamp + "Z");
    const lastModifiedTimedelta =
      nowFnRef.current() - parsedTimestamp.getTime();
    const isRecentlyModified = lastModifiedTimedelta < timeDifference; // 1 minute
    if (differentUser && isRecentlyModified) {
      onRecentlyModifiedRef.current?.({
        user: lastModified.user.display ?? `User ${lastModified.user.id}`,
        timestamp: parsedTimestamp,
      });
    }
  }, [lastModified, currentUser, timeDifference]);
}
