import useCompletionMode from "@/hooks/useCompletionMode";
import { Dialog, Transition } from "@headlessui/react";
import { ExclamationTriangleIcon } from "@heroicons/react/24/outline";
import { formatDistance } from "date-fns";
import nlBE from "date-fns/locale/nl-BE";
import { Fragment, useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import useReportRecentlyModified from "./useReportRecentlyModified";

type ConcurrentUserWarningProps = {
  nowFn?: () => number;
};

type LastModification = {
  user: string;
  timestamp: Date;
};

export default function ConcurrentUserWarning({
  nowFn,
}: ConcurrentUserWarningProps) {
  const [lastModification, setLastModification] =
    useState<LastModification | null>(null);
  const [isOpen, setIsOpen] = useState(false);
  const [completionMode, setCompletionMode] = useCompletionMode();
  const { t } = useTranslation();

  useReportRecentlyModified({
    nowFn,
    onRecentlyModified(lastModified) {
      setLastModification(lastModified);
      setIsOpen(true);
    },
  });

  const handleReadOnly = useCallback(() => {
    setIsOpen(false);
    setCompletionMode("display");
  }, [setCompletionMode]);
  const show = completionMode != "display" && !!lastModification && !!isOpen;
  return (
    <Transition.Root show={show} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-10"
        onClose={() => setIsOpen(false)}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                <div className="sm:flex sm:items-start">
                  <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                    <ExclamationTriangleIcon
                      className="h-6 w-6 text-red-600"
                      aria-hidden="true"
                    />
                  </div>
                  <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                    <Dialog.Title className="font-bold">
                      {t("recently-modified-warning.title")}
                    </Dialog.Title>

                    {lastModification && (
                      <Dialog.Description
                        as="div"
                        className="mt-2"
                        data-testid="warning-message"
                      >
                        <p className="text-base font-medium text-gray-500">
                          {t("recently-modified-warning.message", {
                            who: lastModification.user,
                            when: formatDistance(
                              lastModification.timestamp,
                              new Date(),
                              {
                                addSuffix: true,
                                includeSeconds: true,
                                locale: nlBE,
                              },
                            ),
                          })}
                        </p>
                        <p className="mt-1 text-sm text-gray-500">
                          {t("recently-modified-warning.description")}
                        </p>
                        <p className="mt-1 text-sm text-gray-500">
                          {t("recently-modified-warning.cta")}
                        </p>
                      </Dialog.Description>
                    )}
                  </div>
                </div>
                <div className="mt-5 sm:mt-4 sm:flex sm:flex-row sm:justify-end">
                  <button
                    onClick={() => setIsOpen(false)}
                    className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                  >
                    {t("recently-modified-warning.action-continue")}
                  </button>
                  <button
                    data-testid="readonly-button"
                    onClick={handleReadOnly}
                    className="inline-flex w-full justify-center rounded-md bg-blue-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 sm:ml-3 sm:w-auto"
                  >
                    {t("recently-modified-warning.action-read-only")}
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
