import React from "react";
import { is, number } from "superstruct";

export const CurrentReportBlockIdContext = React.createContext<number | null>(
  null,
);

function useCurrentReportBlockId(id?: number) {
  const currentBlockId = React.useContext(CurrentReportBlockIdContext) ?? id;
  if (!is(currentBlockId, number())) {
    throw new Error("CurrentBlockIdContext is not set");
  }
  return currentBlockId;
}
export default useCurrentReportBlockId;
