import { useMemo, useRef, useState } from "react";
import { currentUserDataOrFalseQuery } from "@/services/reports/useUserData";
import { useTCEConnectionCheck } from "@/components/TCEConnectionHealth";
import { createReportQuery } from "@/services/reports/useReport";
import ReportLoading from "./ReportLoading";
import { ValidationScoreBoardContext } from "@/services/validation/context";
import ValidationScoreBoard from "@/services/validation/ValidationScoreBoard";
import BlockListMananger from "./BlockListManager";
import useCurrentReportId from "@/hooks/useCurrentReportId";
import UnknownError from "@/components/error-displays/UnknownError/UnknownError";
import DemoFeatures from "./DemoFeatures";
import ThreeColumnsPage from "@/components/layout/ThreeColumnsPage/ThreeColumnsPage";
import NavigationSideBar from "@/components/NavigationSideBar";
import { ImperativePanelHandle } from "react-resizable-panels";
import useLaunchedFrom from "@/hooks/useLaunchedFrom";
import { MultiFormRefProvider } from "@/components/MultiFormSubmit/MultiFormRefContext";
import ReportActionToolbar from "./ActionToolbar";
import { useQuery } from "react-query";
import {
  DEFAULT_FHIR_ENDPOINT,
  FHIREndpoint,
  FHIREndpointContext,
} from "@/hooks/useFHIREndpoint";
import useCompositionSync from "@/hooks/smart-web-messaging/useCompositionSync";
import { CodingResultsContainerRefContext } from "@/components/base/CodeCombobox/CodeComboboxResults";
import ConcurrentUserWarning from "@/components/ConcurentUserWarning/ConcurrentUserWarningDialog";

function ReportEditor() {
  const isSynops = useLaunchedFrom("Synops");
  const isHippoProm = useLaunchedFrom("Hippo-PROMS");
  const reportId = useCurrentReportId();

  const { data: user, isLoading: loadingUser } = useQuery(
    currentUserDataOrFalseQuery,
  );

  const { data: report, isLoading: loadingReport } = useQuery({
    ...createReportQuery(reportId),
    staleTime: 1000 * 60, // 1 minute
  });

  const vsb = useMemo(() => new ValidationScoreBoard(), []);
  const ref = useRef<ImperativePanelHandle>(null);
  const reportPageContainer = useRef<HTMLDivElement>(null);
  const [isLeftPanelCollapsed, setIsLeftPanelCollapsed] = useState(false);

  useCompositionSync(reportId, { enabled: isSynops });
  useTCEConnectionCheck();

  if (loadingUser || loadingReport) {
    return <ReportLoading />;
  }
  // FIXME: move this to route guard
  if (!user) {
    return <UnknownError title="We kunnen je gebruikersgegevens te laden." />;
  }
  // FIXME: move this to route guard
  if (!report) {
    return <UnknownError title="We hebben je verslag niet gevonden." />;
  }
  const endpointSerialized = sessionStorage.getItem("fhirEndpoint");
  const endpoint: FHIREndpoint = endpointSerialized
    ? JSON.parse(endpointSerialized)
    : DEFAULT_FHIR_ENDPOINT;
  return (
    <FHIREndpointContext.Provider value={endpoint}>
      <ValidationScoreBoardContext.Provider value={vsb}>
        <ThreeColumnsPage className="ph-no-capture">
          <ThreeColumnsPage.LeftColumn
            ref={ref}
            onCollapse={setIsLeftPanelCollapsed}
            isCollapsed={isLeftPanelCollapsed}
            collapse={() => ref.current?.collapse()}
            defaultSize={10}
          >
            <NavigationSideBar />
          </ThreeColumnsPage.LeftColumn>
          <ThreeColumnsPage.CenterColumn
            isCollapsed={isLeftPanelCollapsed}
            expand={() => ref.current?.expand()}
          >
            <div ref={reportPageContainer}>
              <CodingResultsContainerRefContext.Provider
                value={reportPageContainer}
              >
                {/*<HeaderText createdDate={createdDate} />*/}
                <MultiFormRefProvider>
                  <BlockListMananger reportId={reportId} />
                  <ReportActionToolbar
                    reportId={reportId}
                    className="sticky bottom-0 mt-4 w-full bg-gradient-to-t from-gray-ultralight via-gray-ultralight/80"
                  />
                </MultiFormRefProvider>
                {/*<FooterText />*/}
                <ConcurrentUserWarning />
              </CodingResultsContainerRefContext.Provider>
            </div>
          </ThreeColumnsPage.CenterColumn>
          <ThreeColumnsPage.RightColumn collapsible defaultSize={0}>
            {/*  FIXME: clean up legacy demo features*/}
            {!isHippoProm && <DemoFeatures report={report} />}
          </ThreeColumnsPage.RightColumn>
        </ThreeColumnsPage>
      </ValidationScoreBoardContext.Provider>
    </FHIREndpointContext.Provider>
  );
}

export default ReportEditor;
