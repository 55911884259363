import debounce from "lodash.debounce";
import {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import {
  useMutation,
  UseMutationOptions,
  UseMutationResult,
  useQuery,
  useQueryClient,
  UseQueryResult,
} from "react-query";
import client, { BlockOut, ReportOut } from "@/services/reports/client";
import keyFactory from "@/services/reports/keyFactory";
import { createBlockQuery } from "@/services/reports/useBlock";
import { func, is } from "superstruct";
import { useRegisterSaveAll } from "./useSave";

export type MergeFunc<C> = (remote: C, local: C) => C;

export interface AutoSaveOptions {
  wait: number;
  maxWait?: number;
  enabled: boolean;
}

export interface UseAutoSyncOptions<TContent, TError = unknown> {
  autoSaveOptions?: AutoSaveOptions;
  alertIfUnsavedChanges?: boolean;
  merge?: MergeFunc<TContent>;
  enabled?: boolean;
  autoSaveDraft?: boolean;
  defaultDraft?: TContent;
  onMutationSucces?: UseMutationOptions<
    BlockOut & { content: TContent },
    TError,
    TContent,
    { prevBlock: BlockOut & { content: TContent } }
  >["onSuccess"];
}

export interface UseAutoSyncReturnType<TContent, TError = unknown> {
  draft?: TContent;
  setDraft: Dispatch<SetStateAction<TContent | undefined>>;
  save: () => void;
  saveDebounced: () => void;
  queryResult: UseQueryResult<TContent, unknown>;
  mutationResult: UseMutationResult<
    BlockOut & { content: TContent },
    TError,
    TContent,
    { prevBlock: BlockOut & { content: TContent } }
  >;
}

/**
 * Empty function used to avoid the overhead of `lodash.debounce` if autoSaveOptions are not used.
 */
const EmptyDebounceFunc = Object.assign(() => {}, {
  flush: () => {},
  cancel: () => {},
});

/**
 * useAutoSync handles automatic updates for you.
 * It uses optmistic updates as described here: https://react-query-v3.tanstack.com/guides/optimistic-updates
 * @param options
 * @returns
 */
function useAutoSyncBlockContent<TContent = any>(
  blockId: number,
  reportId: number,
  options?: UseAutoSyncOptions<TContent>,
): UseAutoSyncReturnType<TContent> {
  // determine the block id
  const {
    autoSaveOptions = { wait: 1000, maxWait: 5000, enabled: true },
    alertIfUnsavedChanges = true,
    merge,
    enabled = true,
    onMutationSucces,
    defaultDraft,
    autoSaveDraft = true,
  } = options ?? {};

  const queryClient = useQueryClient();

  // create a stable query key
  const query = useMemo(() => createBlockQuery(blockId), [blockId]);

  const [draft, setDraft] = useState<TContent | undefined>(defaultDraft);
  // create a stable ref to the draft so we can memoize the save function
  const draftRef = useRef<TContent | undefined>(undefined);
  draftRef.current = draft;

  // Retrieve the block
  const queryResult = useQuery({
    ...query,
    enabled,
    refetchOnWindowFocus: false,
    staleTime: 1000 * 60, // 1 minute
    select: (data): TContent => data.content, // only return the content
  });

  // Create a mutator for the block
  const mutationResult = useMutation({
    mutationKey: query.queryKey,
    mutationFn: (content: TContent) =>
      client.v1.updateBlockV1({
        blockId,
        requestBody: { content },
      }) as Promise<BlockOut & { content: TContent }>,
    onMutate(draft) {
      // optimistically update the cache
      queryClient.cancelQueries(query.queryKey);
      const prevBlock = queryClient.getQueryData<
        BlockOut & { content: TContent }
      >(query.queryKey);
      if (!prevBlock) return; // no optmistic update possible
      queryClient.setQueryData(query.queryKey, {
        ...prevBlock,
        content: draft,
      });
      // optimistically clear our draft state because cache is now up to date
      setDraft(undefined);
      return { prevBlock };
    },
    onError(error, draft, context) {
      // revert the optimistic update if the mutation fails
      const { prevBlock } = context ?? {};
      if (prevBlock) queryClient.setQueryData(query.queryKey, prevBlock);
      // reset the draft to the last known draft unless the user made more changes
      if (draft !== undefined) {
        setDraft(draft);
      }
    },
    onSuccess(data, variables, context) {
      const prevReportData = queryClient.getQueryData<ReportOut>(
        keyFactory.report(data?.reportId),
      );
      if (!prevReportData) return;
      queryClient.setQueryData<ReportOut>(keyFactory.report(data.reportId), {
        ...prevReportData,
        lastModified: data.lastModified,
      });
      /**
       *  ⚠️ Don't set the QueryCache of the block because it is possible we have a newer version of the block
       *  for which a mutation is already in flight and an optimistic update is already applied.
       */
      onMutationSucces && onMutationSucces(data, variables, context);
    },
    onSettled() {
      queryClient.invalidateQueries(query.queryKey);
      queryClient.invalidateQueries(keyFactory.reportViews(reportId));
      queryClient.invalidateQueries(keyFactory.getFHIRR5Composition(reportId));
    },
  });
  const { mutate } = mutationResult;

  // return a stable save function
  const save = useCallback(() => {
    if (draftRef.current !== undefined) {
      mutate(draftRef.current);
    }
  }, [mutate]);

  // memoize a debounced save function
  const saveDebounced = useMemo(
    () =>
      !autoSaveOptions.enabled
        ? EmptyDebounceFunc
        : debounce(save, autoSaveOptions.wait, {
            // only pass maxWait to the options if maxWait is defined
            // if maxWait is undefined it is set to 0
            ...(autoSaveOptions.maxWait !== undefined
              ? { maxWait: autoSaveOptions.maxWait }
              : {}),
          }),
    [
      autoSaveOptions.maxWait,
      autoSaveOptions.wait,
      autoSaveOptions.enabled,
      save,
    ],
  );

  // create a function which saves and cancels the debounced save
  const saveAndCancelDebounced = useMemo(
    () => () => {
      saveDebounced.cancel();
      save();
    },
    [save, saveDebounced],
  );

  // clean up saveDebounced on unmount to avoid leaks
  useEffect(() => {
    const prevSaveDebounced = saveDebounced;
    return () => {
      prevSaveDebounced.cancel();
    };
  }, [saveDebounced]);

  // call saveDebounced when the draft changes
  useEffect(() => {
    // check that autoSave is enabled and there are local changes to save
    if (autoSaveOptions.enabled && draft !== undefined && autoSaveDraft) {
      saveDebounced();
    }
  }, [saveDebounced, draft, autoSaveOptions.enabled, autoSaveDraft]);

  // confirm before the user leaves if the draft value isn't saved
  useEffect(() => {
    const shouldPreventUserFromLeaving =
      draft !== undefined && alertIfUnsavedChanges;

    const alertUserIfDraftIsUnsaved = (e: BeforeUnloadEvent) => {
      console.log({ shouldPreventUserFromLeaving });
      if (shouldPreventUserFromLeaving) {
        // Cancel the event
        e.preventDefault(); // If you prevent default behavior in Mozilla Firefox prompt will always be shown
        // Chrome requires returnValue to be set
        e.returnValue = "";
      } else {
        // the absence of a returnValue property on the event will guarantee the browser unload happens
        delete e["returnValue"];
      }
    };

    // only add beforeUnload if there is unsaved work to avoid performance penalty
    if (shouldPreventUserFromLeaving) {
      window.addEventListener("beforeunload", alertUserIfDraftIsUnsaved);
    }
    // document.addEventListener("visibilitychange", saveDraftOnVisibilityChange);
    return () => {
      if (shouldPreventUserFromLeaving) {
        window.removeEventListener("beforeunload", alertUserIfDraftIsUnsaved);
      }
      // document.removeEventListener("visibilitychange", saveDraftOnVisibilityChange);
    };
  }, [alertIfUnsavedChanges, draft, saveAndCancelDebounced]);

  // merge the server data with the local data when the server data changes
  useEffect(() => {
    const serverData = queryResult.data;
    if (serverData !== undefined && merge !== undefined) {
      console.log(
        "merge the server data with the local data when the server data changes",
      );
      setDraft((localData) => {
        if (localData !== undefined) {
          return merge(serverData, localData);
        }
      });
    }
  }, [merge, queryResult.data]);

  const setDraftWrapper = useCallback<
    Dispatch<SetStateAction<TContent | undefined>>
  >(
    (updater) => {
      if (is(updater, func())) {
        const updaterFn = updater as (prevValue: TContent) => TContent;
        setDraft((draft) => {
          const cachedBlock = queryClient.getQueryData<
            BlockOut & { content: TContent }
          >(query.queryKey);
          const prevValue: TContent | undefined = draft ?? cachedBlock?.content;
          if (prevValue === undefined) return draft;
          return updaterFn(prevValue);
        });
      } else {
        setDraft(updater);
      }
    },
    [queryClient, query.queryKey],
  );

  useRegisterSaveAll(save);

  const localDraft = draft ?? queryResult.data;

  return {
    draft: localDraft,
    setDraft: setDraftWrapper,
    save: saveAndCancelDebounced,
    saveDebounced,
    mutationResult,
    queryResult,
  };
}

export default useAutoSyncBlockContent;
