import Tooltip from "../info-cards/Tooltip";
import { LockClosedIcon } from "@heroicons/react/20/solid";

function ReadOnlyLock({ message }: { message: string | undefined }) {
  return (
    <Tooltip>
      <Tooltip.Trigger asChild>
        <LockClosedIcon
          className="mx-1 block h-5 w-5 text-gray-primary"
          aria-hidden="true"
        />
      </Tooltip.Trigger>
      <Tooltip.Content>
        <div className="rounded bg-black p-2 text-sm text-gray-50">
          {message}
        </div>
      </Tooltip.Content>
    </Tooltip>
  );
}

export default ReadOnlyLock;
