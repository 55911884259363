import InlineChromiumBugfix from "@/components/InlineChromiumBugFix";
import { SettingsPanelPortal } from "@/components/layout/SettingsPanel";
import { Transition } from "@headlessui/react";
import PropertiesForm from "./PropertiesForm";
import useSlateInputElement from "@/editor/utils/useSlateInputElement";
import { IConceptInputElement } from "./ConceptInputElement";
import { RenderConceptInputElementProps } from "./types";
import CodingComboboxInput from "@/components/inputs/CodingCombobox";
import {
  codingToConcept,
  conceptAsCoding,
} from "@/data-models/value-models/types";
function RenderComboBoxInputElement({
  element,
  attributes,
  children,
}: RenderConceptInputElementProps<"combobox">) {
  const { children: _, name: __, type, inputType, ...other } = element;
  const { name, update, showProperties, disabled, active } =
    useSlateInputElement<
      IConceptInputElement<"combobox">,
      IConceptInputElement
    >(element);

  return (
    <span {...attributes} tabIndex={-1}>
      <InlineChromiumBugfix />
      <CodingComboboxInput
        {...other}
        name={name}
        className="mx-0.5 inline-flex"
        active={active}
        transform={CODEABLECONCEPT_TRANSFORM}
        disabled={disabled}
      />
      <InlineChromiumBugfix />
      {children}
      <SettingsPanelPortal>
        <Transition show={showProperties} enter="ease-in-out duration-75">
          <PropertiesForm
            actionLabel="Update dropdown"
            onSubmit={update}
            defaultValues={element}
          />
        </Transition>
      </SettingsPanelPortal>
    </span>
  );
}
export default RenderComboBoxInputElement;

const CODEABLECONCEPT_TRANSFORM = {
  input: conceptAsCoding,
  output: codingToConcept,
};
