import { Menu } from "@headlessui/react";
import classNames from "classnames";
import React from "react";

export type BlockMenuLinkProps = React.ButtonHTMLAttributes<HTMLButtonElement>;

export default function BlockMenuButton({
  children,
  className,
  ...props
}: BlockMenuLinkProps) {
  return (
    <Menu.Item
      as="button"
      className={({ active, disabled }) =>
        classNames(
          {
            "bg-white text-gray-600": !active && !disabled,
            "bg-gray-50  text-gray-dark": active && !disabled,
            "bg-gray-50 text-gray-400": disabled,
          },
          "block w-full whitespace-nowrap px-3 py-2 text-left text-sm",
          className,
        )
      }
      {...props}
    >
      {children}
    </Menu.Item>
  );
}
