import { useTranslation } from "react-i18next";
import BlockMenuButton from "./ButtonItem";

type BlockSignButtonProps = {
  onClick: (() => void) | undefined;
  className?: string;
  disabled?: boolean;
  isSigned?: boolean;
};
export function BlockSignButton({
  onClick,
  className,
  disabled,
}: BlockSignButtonProps) {
  const { t } = useTranslation();

  return (
    <BlockMenuButton disabled={disabled} onClick={onClick} className="flex">
      <span className="material-symbols-outlined text-base">check</span>
      <span className="ml-2">{t("sign-block")}</span>
    </BlockMenuButton>
  );
}

export function BlockSignAllButton({
  onClick,
  className,
  disabled,
}: BlockSignButtonProps) {
  const { t } = useTranslation();

  return (
    <BlockMenuButton disabled={disabled} onClick={onClick} className="flex">
      <span className="material-symbols-outlined text-base">done_all</span>
      <span className="ml-2">{t("sign-all-blocks")}</span>
    </BlockMenuButton>
  );
}

export function BlockUnsignButton({
  onClick,
  className,
  disabled,
}: BlockSignButtonProps) {
  const { t } = useTranslation();
  return (
    <BlockMenuButton disabled={disabled} onClick={onClick} className="flex">
      <span className="material-symbols-outlined text-base">remove_done</span>
      <span className="ml-2">{t("unsign-block")}</span>
    </BlockMenuButton>
  );
}
