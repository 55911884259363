import {
  ICodeableConcept,
  IHumanReadableCoding,
} from "@/data-models/value-models/types";
import {
  Control,
  Controller,
  ControllerProps,
  FieldPath,
  FieldValues,
  PathValue,
  useFormContext,
} from "react-hook-form";
import {
  Definition,
  ExpansionContains,
} from "@/services/content/content-client";
import { createValueSetExpansionQuery } from "@/services/content/useValueSetExpansion";
import { createdTypedDropdown, DropdownProps } from "./Dropdown";
import { useQuery } from "react-query";
import Tooltip from "@/components/info-cards/Tooltip";
import CodingTooltip, {
  CODING_TOOLTIP_DELAY_GROUP_ID,
} from "@/components/base/CodingCard/CodingTooltip";
import { useFeatureFlagEnabled } from "posthog-js/react";

type CodingOption = ExpansionContains & IHumanReadableCoding;

const CodingDropdown = createdTypedDropdown<CodingOption>();

const DUMMY_TRANSFORM = {
  input: (o: any) => o,
  output: (o: any) => o,
};

export interface CodingDropdownInputProps<
  TFieldValues extends FieldValues,
  TValue = unknown,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
> extends Omit<ControllerProps<TFieldValues, TName>, "render" | "defaultValue">,
    Omit<
      DropdownProps<ExpansionContains>,
      | "selected"
      | "onSelectedChange"
      | "innerRef"
      | "options"
      | "defaultValue"
      | "name"
    > {
  control?: Control<TFieldValues>;
  defaultValue?: TValue | null;
  options?: IHumanReadableCoding[];
  valueSet?: string;
  valueSetVersion?: string;
  hideLabel?: boolean;
  transform?: {
    input: (value: TValue) => IHumanReadableCoding;
    output: (value: IHumanReadableCoding) => TValue;
  };
}

function CodingDropdownInput<
  TFieldValues extends FieldValues = FieldValues,
  TValue = ICodeableConcept,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
>({
  name,
  valueSet,
  valueSetVersion,
  hideLabel,
  disabled,
  defaultValue = null,
  transform: providedTransform,
  options: providedOptions = [],
  ...props
}: CodingDropdownInputProps<TFieldValues, TValue, TName>) {
  if (!valueSet && providedOptions.length == 0) {
    throw new Error(
      "CodingDropdownInput: Either valueSet or options must be provided",
    );
  }
  const showCodingCardTooltip = useFeatureFlagEnabled(
    "show-coding-card-tooltip",
  );
  const hasDefinedOptions = providedOptions.length > 0;
  const { trigger } = useFormContext();
  // TODO: migrate this to useCodeComboBox
  const { data: options = providedOptions, isError } = useQuery({
    ...createValueSetExpansionQuery(valueSet!),
    enabled: !hasDefinedOptions && disabled != true,
  });

  const transform = providedTransform ?? DUMMY_TRANSFORM;
  return (
    <Controller<TFieldValues, TName>
      name={name}
      defaultValue={defaultValue as PathValue<TFieldValues, TName>}
      render={({ field: { ref, value, onChange, onBlur } }) => (
        <Tooltip
          enabled={showCodingCardTooltip && !!value}
          id={CODING_TOOLTIP_DELAY_GROUP_ID}
        >
          <Tooltip.Trigger>
            <CodingDropdown
              ref={ref}
              disabled={disabled}
              selected={value ? transform.input(value) : null}
              onSelectedChange={(option) => {
                onChange(option && transform.output(option));
                trigger(name);
              }}
              onBlur={onBlur}
              // FIXME: Type assertion is needed because the type of options is not compatible with the type of DropdownProps
              // In theory ExpansionContains can have undefined system code and display
              options={options as CodingOption[]}
              optionToKey={(c) => c.code}
              optionToDisplay={(c) => c.display}
              optionToDescription={getDefinition}
              error={isError}
              {...props}
            />
          </Tooltip.Trigger>
          <Tooltip.Content className="fill-gray-lighter">
            {value && <CodingTooltip coding={transform.input(value)} />}
          </Tooltip.Content>
        </Tooltip>
      )}
    />
  );
}

export default CodingDropdownInput;

const getDefinition = (c: CodingOption) =>
  c.property?.find((p): p is Definition => p.code === "definition")
    ?.valueString;
