import { RenderType } from "./types";
import { Spinner } from "@/Icons";
import React, { useCallback, useImperativeHandle, useRef } from "react";
import { IQuestionnaireContent } from "./models";
import { FocusableBlockContent } from "../useFocusableBlockContent";
import Questionnaire from "@/components/questionnaire-v2";
import { RenderBlockContentProps } from "../RenderBlockContent";
import { useCreateQuestionnaireReducer } from "@/components/questionnaire-v2/createReducer";
import { QAAction } from "@/components/questionnaire-v2/models";
import useAutoSyncBlockContent from "@/hooks/useAutoSyncBlockContent";

interface RenderQuestionnaireBlockContentProps extends RenderBlockContentProps {
  renderType: RenderType;
}

function RenderQuestionnaireBlockContentWithoutRef(
  { reportId, blockId, readOnly }: RenderQuestionnaireBlockContentProps,
  ref: React.Ref<FocusableBlockContent>,
) {
  const { draft, queryResult, setDraft } =
    useAutoSyncBlockContent<IQuestionnaireContent>(blockId, reportId, {
      autoSaveOptions: { wait: 1000, maxWait: 5000, enabled: !readOnly },
    });

  const reducer = useCreateQuestionnaireReducer();
  const questionnaireRef = useRef<HTMLDivElement>(null);
  const dispatch = useCallback(
    (action: QAAction) => {
      setDraft((draft) => {
        if (!draft) {
          console.log(
            "No draft found for questionnaire block. Skipping updates...",
          );
          return;
        }
        const newQuestions = reducer(draft.questions, action);
        const newDraft: IQuestionnaireContent = {
          ...draft,
          questions: newQuestions,
        };
        return newDraft;
      });
    },
    [reducer, setDraft],
  );

  useImperativeHandle(
    ref,
    () => ({ focus: () => questionnaireRef.current?.focus() }),
    [],
  );

  if (queryResult.isLoading) {
    return (
      <div className="flex items-center justify-center">
        <Spinner className="mx-2 inline h-6 w-6 animate-spin text-gray-primary" />
      </div>
    );
  }

  return (
    <Questionnaire ref={questionnaireRef} {...draft} dispatch={dispatch} />
  );
}

const RenderQuestionnaireBlockContent = React.forwardRef(
  RenderQuestionnaireBlockContentWithoutRef,
);
export default RenderQuestionnaireBlockContent;
