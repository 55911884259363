import classNames from "classnames";
import { IBlockMeta, UserTimestamp } from "@/data-models/block";

import React from "react";
import { DraggableProvidedDragHandleProps } from "react-beautiful-dnd";
import BlockCard from "./BlockCard";
import Tooltip from "@/components/info-cards/Tooltip";

export interface BlockContainerProps<
  TBlockIn extends IBlockMeta = IBlockMeta,
  TBlockUpdate extends IBlockMeta = IBlockMeta,
> {
  id: string | number;
  title: string;
  lastModified?: UserTimestamp;
  blockType: string;
  readOnly?: boolean;
  dragHandleProps?: DraggableProvidedDragHandleProps;
  update: (updates: Partial<TBlockUpdate>) => void;
  remove?: () => void;
  insert?: (block: TBlockIn) => void;
  focusContent: () => void;
}

const BlockContainer = {
  Card: BlockCard,
  Toolbar: (props: React.HTMLProps<HTMLDivElement>) => (
    <div
      {...props}
      className={classNames("absolute left-2 top-6", props.className)}
    />
  ),
  Header: (props: React.HTMLProps<HTMLDivElement>) => (
    <div
      {...props}
      className={classNames(
        "inline-flex w-full grow items-center border-b border-gray-light",
        props.className,
      )}
    />
  ),
  Body: ({
    readOnly,
    children,
    ...props
  }: Omit<React.HTMLProps<HTMLDivElement>, "readOnly"> & {
    readOnly?: string | null | undefined;
  }) => (
    <div {...props} className={classNames("relative mt-4", props.className)}>
      {children}
      {readOnly && (
        <Tooltip>
          <Tooltip.Trigger>
            <div className="absolute inset-0 cursor-not-allowed rounded bg-black bg-opacity-[0.05]" />
          </Tooltip.Trigger>
          <Tooltip.Content>
            <div className="rounded bg-black px-2 py-2 text-sm  shadow-lg">
              <p className="text-sm text-gray-50">{readOnly}</p>
            </div>
          </Tooltip.Content>
        </Tooltip>
      )}
    </div>
  ),
};

export default BlockContainer;
